
import { Vue } from "vue-class-component";
import axios from "@/plugins/axios";
import { ElMessageBox } from "element-plus";

interface Result {
  data: any;
  message: string;
  status:boolean;
}

export default class UserPage extends Vue {
  name = "Users";
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    account: localStorage.getItem("account"),
  };
  listUser: any = [];
  formValidateUser: any = {
    email: "",
    password: "",
    passwordConfirm: "",
    skip_2fa: false,
    status: true,
  };
  dialogUser = false;
  dialogUserDetail = false;
  isChanged = false;
authenticResponse : any;
  async created() {
    this.getList();
     this.authenticResponse = await this.$store.state.auth.dataLogin;
     
  }

  async getList() {
    let res: Result;
    await this.$store.dispatch("setLoading", true, { root: true });
    res = await axios.get("user/user-manager", {
      headers: this.headers,
    });
    if (res) {
      this.listUser = res.data;
      const selectedUserId = this.$store.state.auth?.accountSelected?.owner
      for (let user of this.listUser) {
        if (!selectedUserId) {
          user.owner = false
        } else {
          user.owner = user._id == selectedUserId ? true : false
        }
      }
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }

  async handleDelete(id: string) {
    let res: Result;
    ElMessageBox.confirm("Do you want to delete user?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
    }).then(async () => {
      res = await axios.post(
        "user/user-manager/leave",
        { _id: id },
        { headers: this.headers }
      );
      if (res) {
        this.$message.success(res.message);
        this.getList();
      }
    });
  }

  async handleSave() {
    if (this.formValidateUser.password == "") {
      this.$message.warning("Please input the password !");
      return;
    }
    if (this.formValidateUser.passwordConfirm == "") {
      this.$message.warning("Please input the password again !");
      return;
    }
    if (
      this.formValidateUser.password !== this.formValidateUser.passwordConfirm
    ) {
      this.$message.warning("Two inputs don't match!");
      return;
    }
this.formValidateUser.type = this.authenticResponse.type;
    let res: Result;
    res = await axios.post("user/user-manager", this.formValidateUser, {
      headers: this.headers,
    });
    if (res) {
      this.$message.success(res.message);
      this.getList();
      this.dialogUser = false;
      this.formValidateUser = {
        email: "",
        password: "",
        passwordConfirm: "",
      };
    }
  }

  async clickSetup(user: any) {
    this.dialogUserDetail = true;
    this.formValidateUser = user;
  }

  async handleUpdate() {
    if (this.isChanged) {
      if (this.formValidateUser.password == "") {
        this.$message.warning("Please input the password !");
        return;
      }
      if (this.formValidateUser.passwordConfirm == "") {
        this.$message.warning("Please input the password again !");
        return;
      }
      if (
        this.formValidateUser.password !== this.formValidateUser.passwordConfirm
      ) {
        this.$message.warning("Two inputs don't match!");
        return;
      }
    }

    let res: Result;
    res = await axios.post("user/user-manager/update", this.formValidateUser, {
      headers: this.headers,
    });
    if (res.status) {
      this.dialogUserDetail = false;
      this.$message.success(res.message);
      this.getList();
      this.dialogUser = false;
      this.isChanged = false;
      this.formValidateUser = {
        email: "",
        password: "",
        passwordConfirm: "",
      };
    } else {
      this.$message.warning(res.message);
    }
  }
  handleClose() {
    this.formValidateUser = {
      email: "",
      password: "",
      passwordConfirm: "",
    };
    this.dialogUserDetail = false;
    this.isChanged = false;
  }
  changePassWord() {
    if (!this.isChanged) {
      delete this.formValidateUser.password;
      delete this.formValidateUser.passwordConfirm;
    }
  }
}
